<template>
<div class='main'>
  <div class="head">
    <ul>
      <li v-for="(item,index) in headWenzi" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.name}}</i>
      </li>
      <li><a @click="this.$router.push('/coffee/user')">会员</a></li>
      <li><a @click="this.$router.push('/coffee')">咖啡社区</a></li>
      <!-- <li><a @click="this.$router.push('/live')">直播</a></li> -->
    </ul>
  </div>

  <div class="body">

    <div class="body_cont">
      <div class="cont_sear">
        <div class="sear_title">
           <el-tabs
            v-model="activeName"
            type="card"
            class="demo-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane label="咖啡社区" name="coffee">
              <div class="sear_body">
                <div class="sear_left">
                  <div class="body_title">
                    <img class="logo" src="../../static/ossImg/logo.png" alt="">
                    <!-- <img class="logo" src="../../static/ossImg/logo.png" alt=""> -->
                  </div>
                  <div id="sear">
                    <el-input id="searin" v-model="searValue" placeholder="搜索内容">
                      <template #prepend>
                        <el-select v-model="select_value" placeholder="请选择" style="width: 115px">
                          <el-option label="标题" value="0" />
                          <el-option label="作者" value="1" />
                        </el-select>
                      </template>
                    </el-input>
                    <button class="sou" @click="souCoffee">搜索</button>
                  </div>
                  <div class="left_cont">
                    <!-- <span>最热搜索话题：</span>
                    <ul>
                      <li v-for="i in searHuaTi" :key="i">
                        {{i}}
                      </li>
                    </ul> -->
                  </div>
                  <!-- <div class="left_cont">
                    <span>搜索历史：</span>
                    <ul>
                      <li v-for="i in searGuanJianCi" :key="i" style="margin-right: 30px;">
                        {{i.name}}
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div class="sear_center">
                  <h3><img src="../../static/ossImg/coffeCup.png">热门话题文章</h3>
                  <ul v-loading="loadingRemenList">
                    <li v-for="i in remenList" :key="i" @click="tieziDetails(i)">
                      {{i.title}}
                    </li>
                  </ul>
                </div>
                <div class="sear_right">
                  <!-- <VideoTest/> -->
                  <h3><img src="../../static/ossImg/coffeCup.png">赛事/探店</h3>
                  <div id="video"></div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="关键词目录" name="keyword">
              <div class="sear_body">
                <div class="sear_left">
                  <div id="sear">
                    <el-input id="searin" v-model="searValue" placeholder="品牌/品种/产地"></el-input>
                    <button class="sou" @click="sou">搜索</button>
                  </div>
                  <el-divider />
                  <div class="left_cont">
                    <span class="keyword">关键词索引目录：</span>
                    <ul>
                      <li v-for="(i,k) in searGuanJianCi" :key="k">
                        <p>
                          {{i.name}}
                        </p>
                        <span class="li_span" v-for="item in GuanJianCiList[k]" :key="item">
                          <h4>{{item.name}}</h4>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>

      <div class="cont_list">
        <div class="list_title">
          <el-row :gutter="20">
            <el-col :span="3"></el-col>
            <el-col :span="2">阅读</el-col>
            <el-col :span="1.5">评论</el-col>

            <el-col :span="3"></el-col>
            <el-col :span="1.5">主题</el-col>

            <el-col :span="4"></el-col>
            <el-col :span="1.5">作者</el-col>

            <el-col :span="2">更新时间</el-col>
            <el-col :span="1"></el-col>

            <el-col :span="2">
              <el-button @click="toPublications">发表文章</el-button>
            </el-col>
            <el-col :span="2">
              <!-- <el-button>退出登录</el-button> -->
            </el-col>
          </el-row>
        </div>

        <div class="list_body">
          <el-row :gutter="20">
            <el-col :span="3" class="left_list">
              <el-tree default-expand-all :data="treeList" @node-click="handleNodeClick" />
            </el-col>
            <el-col :span="15" class="center_list" v-loading="loadingtieziList">
              <el-row :gutter="15" v-for="i in tieziList" :key="i">
                <el-col :span="3">{{i.pageview}}</el-col>
                <el-col :span="2">{{i.replies}}</el-col>

                <el-col :span="13" @click="tieziDetails(i)">{{i.title}}</el-col>
                <el-col :span="2">{{i.memberNickName}}</el-col>

                <el-col :span="4">{{i.establishTime}}</el-col>
              </el-row>
              <!-- 底部分页 -->
              <div class="showfooter">
                <el-pagination v-if="totalnum<pagesize?false:true"
                  v-model:currentPage="currentPage"
                  :page-size="pagesize"
                  layout="prev, pager, next, jumper"
                  :total="totalnum"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </el-col>
            <el-col :span="6" class="right_list">
              <div class="userlist">
                <h4>
                  <span>人气用户</span>
                  <div @click="userList(1)"><el-icon><refresh /></el-icon>换一批</div>
                </h4>
                <ul v-loading="loadingUserList">
                  <li v-for="(i, k) in userListdata" :key="k">
                    <img :src="i.icon" alt="">
                    <p>{{i.realName}}</p>
                    <el-button size="small"
                    :class="i.concern==1?'disabledbtn':''"
                     @click="userGuanZhu(i,k)"
                    >{{i.concern==1?'取消关注':'关注'}}</el-button>
                  </li>
                  <span class="no_login_user" v-if="!loadingUserList&&userListdata.length==0">
                    暂未登录!!
                  </span>
                </ul>
              </div>

              <div class="deallist">
                <div id="table_head">
                  <tr>
                    <th v-for="i in tableTitle" :key="i"
                    :class="i==0?'thname':''">{{i}}</th>
                  </tr>
                </div>
                <vue-seamless-scroll :data="tableCont"
                :class-option="optionHover"
                class="seamless-warp"
                v-loading="loading">
                  <div id="table_body">
                    <table>
                      <tr class="table_tr" v-for="(k,index) in tableCont" :key="index">
                        <td >{{k.productName}}</td>
                        <td style="color:#E90000;">{{k.price}}</td>
                        <td>{{k.amount}}</td>
                        <td>{{k.siteName}}</td>
                        <td>{{k.ts}}</td>
                      </tr>
                    </table>
                  </div>
                </vue-seamless-scroll>
                <div class="table_button">
                  <el-button size="mini" @click="this.$router.push('/map')">快捷买入</el-button>
                  <el-button size="mini" @click="this.$router.push('/map')">快捷卖出</el-button>
                  <el-button size="mini" @click="loading=true,initdata()">刷新行情</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>

  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import { onMounted, ref } from 'vue-demi'
import Player from 'xgplayer'
import http from '../../api/http'
import { homeAdvertiseList } from '../../api/api'
import Footer from '../index/Footer.vue'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'
// import VideoTest from '../../views/index/videozibang/Video.vue'

// 获取成交明细
const getSuccessOrderList = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderList', data)
}
// 获取热门话题
const queryPopularPostss = (data) => {
  return http.get('/mall-portal/mall/post/queryPopularPosts', data)
}
// 获取首页帖子
const queryPcPostByPage = (data) => {
  return http.get('/mall-portal/mall/post/queryPcPostByPage', data)
}
// 查询关键词一级分类
const queryForumFirstLevelClassification = (data) => {
  return http.get('/mall-portal/mall/post/queryForumFirstLevelClassification')
}
// 根据二级查询子节点
const queryChildNodeByParentId = (parentId) => {
  return http.get(`/mall-portal/mall/post/queryChildNodeByParentId/${parentId}`)
}
// 按关键词查询帖子或资讯
const searchForPostsOrNewsByKeyword = (data) => {
  return http.get('/mall-portal/mall/post/searchForPostsOrNewsByKeyword', data)
}

// 获取人气用户
const queryPopularUsers = (data) => {
  return http.get('/mall-portal/mall/post/queryPopularUsers', data)
}
// 关注人气用户
const followPopularUsers = (data) => {
  return http.post('/mall-portal/mall/post/followPopularUsers', data)
}
// 取消关注人气用户
const unfollowPopularUsers = (data) => {
  return http.post('/mall-portal/mall/post/unfollowPopularUsers', data)
}

// 帖子搜索框
const portalSearch = (data) => {
  return http.get('/mall-portal/mall/post/search', data)
}

const headWenzi = [
  { name: '首页', path: '/' },
  { name: '资讯', path: '/coffee/information' }
  // { name: '直播', path: '/live' },
  // { name: '咖啡社区', path: '/coffee' },
  // { name: '会员', path: '/coffee/user' }
]

const tableTitle = ['商品名称', '成交价格', '成交量', '地区', '成交时间']
export default {
  name: 'coffeeCommunity',
  data () {
    return {
      loading: ref(true), // 加载框
      loadingtieziList: ref(true), // 帖子loading
      loadingUserList: ref(true), // 人气用户loading
      loadingRemenList: ref(true), // 热门话题loading
      // checkAll: ref(false), // 多选框

      // 底部分页
      totalnum: ref(100), // 存放总数据个数
      pagesize: ref(15), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页

      activeName: ref('coffee'),
      select_value: ref('0'), // 搜索选择框内容
      searValue: ref(''), // 搜索框内容
      searHuaTi: ref(['咖啡', '咖啡文化', '行业品牌', '活动']), // 话题内容
      searGuanJianCi: ref([]), // 关键词索引
      GuanJianCiList: ref([]), // 关键词list
      GuanListValueId: ref(''), // 选中的关键词id
      remenList: ref([]), // 话题列表

      treeList: ref([
        {
          label: '咖啡社区',
          children: [
            {
              label: '—咖啡种类'
            },
            {
              label: '—豆子筛选'
            },
            {
              label: '—咖啡文化'
            },
            {
              label: '—行业品牌'
            },
            {
              label: '—探店活动'
            },
            {
              label: '—咖啡周边'
            }
          ]
        }
        // {
        //   label: '资讯中心',
        //   children: [
        //     {
        //       label: '咖啡资讯'
        //     },
        //     {
        //       label: '行业动态'
        //     },
        //     {
        //       label: '库存报告'
        //     },
        //     {
        //       label: '持仓报告'
        //     },
        //     {
        //       label: '咖啡金融'
        //     }
        //   ]
        // },
        // {
        //   label: '咖啡产区',
        //   children: [
        //   ]
        // }
      ]), // 咖啡左侧list
      tieziList: ref([]), // 帖子的内容

      currentPageStatus: ref('0'), // 0默认主分页  1分类  2搜索
      userNum: ref(1), // 当前是人气用户第几页
      userListdata: ref([]), // 人气用户
      tableCont: ref([])
    }
  },
  components: { Footer },
  computed: {
    optionHover () {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async initdata () { // 初始化成交明细
      const data = {
        rankingClassification: 3
      }
      const getList = await getSuccessOrderList(data)
      // console.log(getList)
      this.tableCont = getList.data.data
      this.loading = false
    },
    async articles (num) { // 初始化文章
      const page = {
        pageSize: 15,
        pageNum: num,
        postOrInformation: 0
      }
      const articlesList = await queryPcPostByPage(page)
      // console.log(articlesList)
      if (articlesList.data.code === 200) {
        this.tieziList = articlesList.data.data.list
        if (num === 1) {
          this.totalnum = articlesList.data.data.total
        }
        this.loadingtieziList = false
      }
      // console.log(articlesList)
    },
    async HotTopics () { // 初始化热门话题
      const data = {
        pageNum: 1,
        pageSize: 6,
        postOrInformation: 0
      }
      const res = await queryPopularPostss(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.remenList = res.data.data.list
        this.loadingRemenList = false
      }
    },
    async GuanJianCiInit () { // 关键词一级分类
      const res = await queryForumFirstLevelClassification()
      // console.log(res)
      if (res.data.code === 200) {
        this.searGuanJianCi = res.data.data
        // let zires = ''
        this.GuanJianCiList = []
        res.data.data.forEach(item => {
          // console.log(item)
          if (item.name === '咖啡社区') {
            // this.guanjianzi = item
            // this.initDataTwo(item.id)
            const resChild = queryChildNodeByParentId(item.id)
            resChild.then(resdata => {
              if (resdata.data.code === 200) {
                // this.GuanJianCiList = res.data.data
                const arr = []
                // console.log(resdata.data.data)
                resdata.data.data.forEach(i => {
                  arr.push({
                    label: '— ' + i.name,
                    id: i.id
                  })
                })
                this.treeList[0].children = arr
                // console.log(arr)
              }
            }).catch(err => {
              console.log(err)
            })
          }
        })
        // for (const i of this.searGuanJianCi) {
        //   // console.log(i)
        //   zires = await queryChildNodeByParentId(i.id)
        //   // console.log(zires)
        //   this.GuanJianCiList.push(zires.data.data)
        //   zires = ''
        // }
        // console.log(this.GuanJianCiList)
      }
    },
    async userList (num) { // 查询人气用户
      this.loadingUserList = true
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      if (!userId) {
        this.loadingUserList = false
      } else {
        this.userNum += num
        // console.log(this.userNum)
        const data = {
          pageNum: this.userNum,
          pageSize: 5,
          userId: userId ? userId.id : null
        }
        const res = await queryPopularUsers(data)
        // console.log(res)
        if (res.data.code === 200) {
          this.loadingUserList = false
          if (res.data.data.list.length < 5) {
            this.userNum = 0
            this.userList(1)
            return
          }
          this.userListdata = res.data.data.list
          // console.log(this.userListdata)
        }
      }
    },
    async userGuanZhu (item, k) { // 关注取关用户
      console.log(item)
      const data = {
        popularUsersId: item.id
      }
      if (item.concern === 0) {
        const res = await followPopularUsers(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.userListdata[k].concern = 1
        }
      } else {
        const res = await unfollowPopularUsers(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.userListdata[k].concern = 0
        }
      }
    },
    toPublications () { // 发表文章
      this.$router.push({
        path: '/coffee/publications',
        query: {
          postOrInformation: '0'
        }
      })
    },
    handleCurrentChange (val) { // 分页
      console.log(`current page: ${val}`)
      this.currentPage = val
      if (this.currentPageStatus === '1') {
        // console.log(111)
        this.initGuanjianci()
      } else if (this.currentPageStatus === '0') {
        // console.log(222)
        this.articles(this.currentPage)
      } else if (this.currentPageStatus === '2') {
        this.souCoffee()
      }
    },
    handleClick () { // 点击切换

    },
    tieziDetails (item) { // 查看帖子详情
      console.log(item)
      this.$router.push({
        path: '/coffee/communityarticle',
        query: {
          id: item.id
        }
      })
    },
    async handleNodeClick (e) { // 点击分类
      console.log(e)
      this.currentPage = 1
      this.currentPageStatus = '1'
      this.GuanListValueId = e.id
      if (e.id) {
        this.initGuanjianci(e)
      } else {
        this.currentPageStatus = '0'
        this.articles(1)
      }
    },
    async initGuanjianci (e) {
      this.loadingtieziList = true
      const data = {
        pageSize: 15,
        pageNum: this.currentPage || 1,
        postOrInformation: 0,
        keyWords: e.id || this.GuanListValueId
      }
      const res = await searchForPostsOrNewsByKeyword(data)
      console.log(res)
      if (res.data.code === 200) {
        this.tieziList = res.data.data.list
        this.totalnum = res.data.data.total
      }
      this.loadingtieziList = false
    },
    async souCoffee () { // 搜索按钮确定
      this.loadingtieziList = true
      // this.currentPage = 1
      this.currentPageStatus = '2'
      const data = {
        pageSize: 15,
        pageNum: this.currentPage || 1,
        postOrInformation: 0,
        authorOrTitle: this.select_value,
        keyWords: this.searValue
      }
      const res = await portalSearch(data)
      console.log(res)
      if (res.data.code === 200) {
        this.tieziList = res.data.data.list
        // if (this.currentPage === 1) {
        //   this.totalnum = res.data.data.total
        // }
        this.totalnum = res.data.data.total
        this.loadingtieziList = false
      }
    }
  },
  mounted () {
    this.articles(1) // 初始化文章
    this.HotTopics() // 初始化热门话题
    this.initdata() // 初始化成交明细
    this.GuanJianCiInit() // 关键词一级分类
    this.userList(0) // 查询人气用户
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    // let videoData = reactive(null)
    const getPic = async () => {
      const data = {
        types: 4
      }
      const res = await homeAdvertiseList(data)
      console.log(res)
      if (res.data.code === 200) {
        return res.data.data
      }
      // console.log(videoData)
    }

    onMounted(() => {
      getPic().then(res => {
        console.log(res)
        const player = new Player({
          id: 'video',
          autoplay: false,
          width: 347,
          height: 256,
          url: res[0].pic,
          poster: 'https://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg'
          // playbackRate: [0.5, 0.75, 1, 1.5, 2] // 传入倍速可选数组
        })
        return player
      }).catch(err => {
        console.log(err)
      })
    })
    return {
      headWenzi,
      tableTitle,
      handleSizeChange
    }
  }
}
</script>

<style lang='scss'>
//@import ''; 引入公共css类
.main{
  width: 100%;
  background: #f2efed;
  // height: 800px;
  font-family: Microsoft YaHei;
  color: #333333;
  .head{
    height: 30px;
    background: #FFFFFF;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      font-size: 14px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        color: #333333;
        cursor: pointer;
        .na-text{
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
      li:nth-last-child(3),li:nth-last-child(2),li:last-child{
        float: right;
        a{
          color: #666;
        }
      }
    }
  }
  .body{
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: 20px;
    .body_cont{
      .cont_sear{
        .sear_title{
          .el-tabs{
            .el-tabs__content{
              #pane-coffee{
                .sear_body{
                  display: flex;
                  .sear_left{
                    flex: 1;
                    padding: 20px 15px;
                    .body_title{
                      padding: 10px;
                      margin-bottom: 30px;
                      display: flex;
                      justify-content: center;
                      img{
                        width: 165px;
                        height: 135px;
                      }
                    }
                    #sear{
                      flex: 1;
                      display: flex;
                      align-items: center;
                      #searin{
                        box-sizing: border-box;
                        background: 0;
                        flex: 1;
                        height: 40px;
                        padding: 0 15px;
                        outline: none;
                        border: 1px solid;
                      }
                      #searin::-webkit-input-placeholder {
                        color: #999999;
                      }
                      #searin:-moz-placeholder {
                        color: #999999;
                      }
                      #searin:-ms-input-placeholder {
                        color: #999999;
                      }
                      #searin::-ms-input-placeholder{
                        color: #999999;
                      }
                      .sou{
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        width: 87px;
                        height: 40px;
                        background: #4f2300;
                      }
                      .el-input-group__prepend{
                        .el-select{
                          width: 80px !important;
                        }
                      }
                    }
                    .left_cont{
                      display: flex;
                      margin: 30px 0;
                      span{
                        width: 155px;
                        font-size: 19px;
                        text-align-last: justify;
                        font-weight: bold;
                        line-height: 40px;
                        color: #3395F1;
                      }
                      ul{
                        flex: 1;
                        display: block;
                        li{
                          line-height: 40px;
                          float: left;
                          margin-right: 10px;
                          font-size: 17px;
                          font-weight: 400;
                          color: #333333;
                        }
                      }
                    }
                  }
                  .sear_center{
                    padding: 0 15px 20px;
                    flex: 1;
                    border-left: 4px solid #f2efed;
                    border-right: 4px solid #f2efed;
                    h3{
                      img{
                        width: 33px;
                        height: 33px;
                      }
                      display: inline-block;
                      height: 40px;
                      font-size: 20px;
                      font-weight: bold;
                      line-height: 40px;
                    }
                    ul{
                      min-height: 210px;
                      margin: 12px 0;
                      display: flex;
                      flex-flow: column;
                      li{
                        cursor: pointer;
                        flex: 1;
                        margin: 4px 0;
                        padding: 5px;
                        background: #EEEEEE;
                        line-height: 25px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        border: transparent 1px solid;
                      }
                    }
                  }
                  .sear_right{
                    flex: 1;
                    padding: 0 15px 20px;
                    h3{
                      img{
                        width: 33px;
                        height: 33px;
                      }
                      display: inline-block;
                      height: 40px;
                      font-size: 20px;
                      font-weight: bold;
                      line-height: 40px;
                    }
                    #video{
                      margin: 20px 0;
                    }
                  }
                }
              }
              #pane-keyword{
                .sear_body{
                  display: flex;
                  .sear_left{
                    flex: 1;
                    padding: 20px 15px;
                    box-sizing: border-box;
                    #sear{
                      width: 520px;
                      flex: 1;
                      display: flex;
                      margin: 0 auto;
                      align-items: center;
                      .sou{
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        width: 87px;
                        height: 40px;
                        background: #4f2300;
                      }
                    }
                    .left_cont{
                      display: flex;
                      max-width: 1200px;
                      .keyword{
                        width: 155px;
                        font-size: 19px;
                        text-align-last: justify;
                        font-weight: bold;
                        line-height: 40px;
                        color: #3395F1;
                      }
                      ul{
                        flex: 1;
                        display: flex;
                        li{
                          display: flex;
                          flex-flow: column;
                          flex: 1;
                          line-height: 40px;
                          float: left;
                          margin-right: 10px;
                          font-size: 17px;
                          font-weight: 400;
                          color: #333333;
                          .li_span{
                            padding-left: 20px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            line-height: 40px;
                            h4{
                              display: inline;
                              cursor: pointer;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .cont_list{
        .list_title{
          height: 62px;
          background: #F1F1F1;
          border: 1px solid #B5B4B4;
          line-height: 62px;
          .el-row{
            width: 100%;
            .el-col{
              font-size: 19px;
              font-weight: bold;
              .el-checkbox{
                .el-checkbox__label{
                  font-size: 19px;
                  font-weight: bold;
                }
              }
              .el-button{
                // width: 110px;
                background: #000000;
                color: #FFFEFE;
                border-radius: 10px;
              }
            }
          }
        }
        .list_body{
          // height: 40px;
          line-height: 40px;
          .el-row{
            padding: 0 10px;
            .left_list{
              min-height: 200px;
              box-sizing: border-box;
            }
            .center_list{
              border-right: 4px solid #f2efed;
              border-left: 4px solid #f2efed;
              // margin-left: 10px;
              // padding: 0 !important;
              .el-row{
                padding: 0;
                .el-col{
                  white-space:nowrap; // 一行显示
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                .el-col-13{
                  cursor: pointer;
                }
              }
              .showfooter{
                height: 100px;
                // background: red;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-pagination{
                  // line-height: 100px;
                  .el-pager{
                    margin: 0 20px;
                    .number,.btn-quicknext{
                      margin: 0 5px;
                      width: 40px;
                      height: 40px;
                      border: 1px solid transparent;
                      line-height: 40px;
                    }
                    .active{
                      background: #ffffff;
                      color: #C6824E;
                      border-color: #C6824E;
                    }
                    .number:hover{
                      color: #C6824E;
                    }
                    .el-icon{
                      height: 40px;
                      line-height: 40px;
                    }
                  }
                  .btn-prev,.btn-next,.el-pagination__jump{
                    height: 40px;
                    line-height: 40px;
                    .el-input{
                      height: 40px;
                      .el-input__inner{
                        height: 40px;
                      }
                    }
                  }
                }
              }
            }
            .right_list{
              padding: 0 !important;
              .userlist{
                padding: 0 10px;
                h4{
                  font-size: 19px;
                  font-weight: bold;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 2px solid #999999;
                  div{
                    font-weight: 400;
                    font-size: 16px;
                    cursor: pointer;
                  }
                }
                ul{
                  min-height: 250px;
                  display: flex;
                  flex-flow: column;
                  border-bottom: 2px solid #999999;
                  li{
                    padding: 5px 0;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                      border-radius: 50%;
                      height: 40px;
                      width: 40px;
                    }
                    p{
                      flex: 1;
                      margin: 0 5px;
                      white-space:nowrap; // 一行显示
                      text-overflow: ellipsis; // 溢出变为省略号
                      overflow: hidden;
                    }
                    .el-button{
                      width: 90px;
                      // height: 30px;
                      border: 1px solid #1CC6FE;
                      color: #00B1EB;
                      text-align: center;
                      border-radius: 5px;
                    }
                    .disabledbtn{
                      border: 1px solid #999999;
                      color: #666666;
                    }
                  }
                  .no_login_user{
                    text-align: center;
                    color: #C6824E;
                    line-height: 250px;
                    font-size: 22px;
                  }
                }
              }
              .deallist{
                #table_head{
                  margin-top: 10px;
                  width: 100%;
                  tr{
                    width: 100%;
                    font-size: 12px;
                    display: flex;
                    th{
                      flex: 1;
                      background: #4f2300;
                      font-weight: bold;
                      color: #FFFFFF;
                    }
                    .thname{
                      min-width: 70px;
                    }
                  }
                }
                .seamless-warp{
                  height: 150px;
                  overflow: hidden;
                    #table_body{
                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    table{
                      font-size: 12px;
                      .table_tr{
                        box-sizing: border-box;
                        flex: 1;
                        display: flex;
                        height: 30px;
                        line-height: 30px;
                        td{
                          text-align: center;
                          // display: flex;
                          border-bottom: 1px solid #f2efed;
                          flex: 1;
                          white-space:nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        }
                      }
                    }
                  }
                }
                .table_button{
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }
  .divfooter{
    background: #171717;
  }
}
</style>
